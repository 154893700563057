/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages

        const $mobileNav = $('.banner-mobile');
        const mobileNavOpen = document.querySelector('.banner-mobile-main');

        // Open search bar
        $('.search-btn').on('click', function(e) {
          e.preventDefault();
          e.stopPropagation();

          //Close mobile navigation before opening search bar.
          const mobileNavState = mobileNavOpen.classList.contains('show');

          if(mobileNavState) {
            $mobileNav.find('.mobile-burger').toggleClass('open');
            $mobileNav.find('.banner-mobile-main').toggleClass('show');
          }


          $('.overhead-search-wrap').toggleClass('show');
        });

        /**
         * Mobile nav
         */

        // Open nav.
        $mobileNav.find('.mobile-burger').on('click', function() {

          if($('.overhead-search-wrap').hasClass('show')) {
            $('.overhead-search-wrap').toggleClass('show');
          }

          $(this).toggleClass('open');
          $mobileNav.find('.banner-mobile-main').toggleClass('show');
        });

        // Go to second level
        $mobileNav.find('.main-screen-first .mobile-first-nav-link.link-has-children').on('click', function(e) {
          e.preventDefault();
          e.stopPropagation();

          const secondLevelIndex = $(this).data('index');

          $mobileNav.find('.main-screen-first').addClass('hide');
          $mobileNav.find('.main-screen-second').addClass('show');
          $mobileNav.find(`.main-screen-second .nav-after-screen[data-index="${secondLevelIndex}"]`).addClass('show');
        });

        // Go back to first level
        $mobileNav.find('.main-screen-second .mobile-nav-return').on('click', function(e) {
          e.preventDefault();
          e.stopPropagation();

          $mobileNav.find('.main-screen-second .nav-after-screen').removeClass('show');
          $mobileNav.find('.main-screen-second').removeClass('show');
          $mobileNav.find('.main-screen-first').removeClass('hide');
        });

        // Go to third level
        $mobileNav.find('.main-screen-second .mobile-after-nav-link.link-has-children').on('click', function(e) {
          e.preventDefault();
          e.stopPropagation();

          const thirdLevelindex = $(this).data('index');

          $mobileNav.find('.main-screen-second .nav-after-screen').removeClass('show');
          $mobileNav.find('.main-screen-second').removeClass('show');
          $mobileNav.find('.main-screen-third').addClass('show');
          $mobileNav.find(`.main-screen-third .nav-after-screen[data-index="${thirdLevelindex}"]`).addClass('show');
        });

        // Go back to second level
        $mobileNav.find('.main-screen-third .mobile-nav-return').on('click', function(e) {
          e.preventDefault();
          e.stopPropagation();

          const secondLevelIndex = $(this).data('returns');

          $mobileNav.find('.main-screen-third').removeClass('show');
          $mobileNav.find('.main-screen-third .nav-after-screen').removeClass('show');
          $mobileNav.find('.main-screen-second').addClass('show');
          $mobileNav.find(`.main-screen-second .nav-after-screen[data-index="${secondLevelIndex}"]`).addClass('show');
        });
        // ./Mobile nav

        function getWindowWidth() {
          var bodyOverflowX = $('body').css('overflow-x');
          var bodyOverflowY = $('body').css('overflow-y');
          $('body').css('overflow', 'hidden');
          var windowWidth = $(window).width();
          $('body').css({
            'overflowX': bodyOverflowX,
            'overflowY': bodyOverflowY
          });

          return windowWidth;
        }

        // Social wall
        const $socialWall = $('.twitter-feed');
        if ($socialWall.length > 0) {
          const socialCarousel = $socialWall.find('.owl-carousel').owlCarousel({
            loop: false,
            nav: true,
            navText: ['', ''],
            dots: false,
            responsive: {
              0: {
                items: 1,
              },
              992: {
                items: 3,
              },
            }
          });

          $socialWall.find('.owl-prev').hide();

          socialCarousel.on('changed.owl.carousel', function(e) {
            var perPage = 3;

            if ($(window).innerWidth() < 992) {
              perPage = 1;
            }

            if (e.item.index >= e.item.count - perPage) {
              // Reached the end
              $socialWall.find('.owl-next').hide();
            } else {
              $socialWall.find('.owl-next').show();
            }

            if (e.item.index <= 0) {
              // Reached the beginning
              $socialWall.find('.owl-prev').hide();
            } else {
              $socialWall.find('.owl-prev').show();
            }
          });
        }

        // Popup
        const $popupFullscreen = $('.popup-fullscreen');
        const $popupBottom = $('.popup-bottom');
        if ($popupFullscreen.length > 0) {
          $popupFullscreen.find('.popup-container').animate({
            'top': '0',
            'opacity': '1'
          }, 200);
        }

        $('.popup-fullscreen, .btn-popup-close, .popup-option-close').on('click', function () {
          // fullscreen popup
          if ($popupFullscreen.length > 0) {
            $popupFullscreen.find('.popup-container').animate({
              'top': '-30px',
              'opacity': '0'
            }, 200, function () {
              $popupFullscreen.fadeOut(200);
            });
          }

          // bottom popup
          if ($popupBottom.length > 0) {
            $popupBottom.remove();
          }

          // set a cookie
          var date = new Date();
          date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000)); // expire in 7 days
          var expiration = date.toUTCString();

          document.cookie = "popup_seen=true; expires=" + expiration + "; path=/";
        });

        $popupFullscreen.find('.popup-container').on('click', function (e) {
          e.stopPropagation();
        });

        $('.popup-option-donate').on('click', function (e) {
          e.preventDefault();
          e.stopPropagation();

          // set a cookie
          var date = new Date();
          date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000)); // expire in 7 days
          var expiration = date.toUTCString();

          document.cookie = "popup_seen=true; expires=" + expiration + "; path=/";
          window.location = $(this).attr('href');
        });

        //Function used to close all open menus prior to opening a new one
        function resetSubMenus(topLevel = false) {
          $('.nav-link-sub-menu').removeClass('sub-menu-open');

          if (topLevel) {
            $('.menu-item').removeClass('faded');
          }
        }

        //Toggle desktop submenu
        //Could probably be improved
        const $links_with_submenu = $('.menu-item.has-children');
        if($links_with_submenu.length > 0) {
          $links_with_submenu.each(function(index) {

            const $menuItem = $(this);

            //Get current link id
            const link_id = ($(this).attr('id'));
            const link_number = link_id.substring(link_id.length - 1);

            //Use link id to match corresponding submenu
            var sub_menu_prefix = '#sub-menu-';
            var sub_menu_id = sub_menu_prefix + link_number;

            $(this).on('click', function(e){
              //Prevent dafult page refresh on link click
              e.preventDefault();
              e.stopPropagation();

              var a_menu_is_open = $('.nav-link-sub-menu').hasClass('sub-menu-open');
              var my_menu_state = $(sub_menu_id).hasClass('sub-menu-open');

              //Login used to check if any other menus are open
              //piror to opening self.
              if(my_menu_state) {
                resetSubMenus(true);
              }
              else if(!my_menu_state) {
                if(a_menu_is_open) {
                  resetSubMenus(true);
                  $(sub_menu_id).addClass('sub-menu-open');
                }
                else {
                  $(sub_menu_id).addClass('sub-menu-open');
                }

                $('.menu-item').each(function() {
                  if ($(this).get()[0] != $menuItem.get()[0]) {
                    $(this).addClass('faded');
                  }
                });
              }

            });
          });
        }

        // Third level menu
        const $thirdLevelTriggers = $('.sub-menu-link.sub-menu-has-children');

        if ($thirdLevelTriggers.length) {
          $thirdLevelTriggers.each(function() {
            $(this).on('click', function(e) {
              e.preventDefault();
              e.stopPropagation();

              const opensId = $(this).data('opens');

              // Close all menus
              resetSubMenus();

              // Open the correct sub menu
              const $thirdLevelSub = $(`#third-sub-menu-${opensId}`);
              $thirdLevelSub.addClass('sub-menu-open');
            })
          });
        }

        // Return to second level
        const $secondLevelReturns = $('.third-return-link');

        if ($secondLevelReturns.length) {
          $secondLevelReturns.each(function() {
            $(this).on('click', function(e) {
              e.preventDefault();
              e.stopPropagation();

              const returnId = $(this).data('returns');

              // Close all menus
              resetSubMenus();

              // Open the correct sub menu
              const $secondLevelSub = $(`#sub-menu-${returnId}`);
              $secondLevelSub.addClass('sub-menu-open');
            });
          });
        }

        // Click-off
        $(window).on('click', function(e) {
          if (!$(e.target).hasClass('sub-menu-open') && !$(e.target).parents('.sub-menu-open').length && !$(e.target).hasClass('menu-item')) {
            resetSubMenus(true);
          }
        });

        // Toggle mobile menu
        $('#toggleMobileMenu').click(function () {
          $('#toggleMobileMenu, #menuIcon, .mobileMenuHolder ').toggleClass('open');
          $('#mobileMenu').slideToggle();
        });

        // Toggle mobile menu children
        $('#mobileMenu .toggle').click(function () {
          $(this).toggleClass('open').parent().children('ul').slideToggle(100);
        });

        // Expand mobile menu if active page is within sub-menu
        if ($('#mobileMenu .navbar-nav-mobile li').hasClass('active')) {
          $('#mobileMenu .navbar-nav-mobile li.active ul').css('display', 'block');
        }

        // Toggle sidebar menu
        if ($(window).width() <= 991) {
          $('#toggleSidebar').click(function () {
            $('#toggleSidebar, #sidebarMenu').toggleClass('open');
            $('#sidebarMenu').slideToggle();
          });
        }

        // Toggle sidebar menu children
        $('#sidebarMenu .sidemenu .hasChildren #subMenuToggle').click(function () {
          var id = $(this).attr('data-id');
          $(this).toggleClass('open');
          $('ul.childSubMenu.toggle-id-' + id).slideToggle();
        });

        // Expand sidebar menu if active page is within sub-menu
        if ($('#sidebarMenu .sidemenu ul.childSubMenu li').hasClass('active')) {
          var id = $('#sidebarMenu .sidemenu ul.childSubMenu li.active').parent().attr('data-id');
          $('#sidebarMenu .sidemenu li.hasChildren .toggle-' + id).toggleClass('open');
          $('#sidebarMenu .sidemenu ul.childSubMenu.toggle-id-' + id).slideToggle();
        }

        // Search toggle - desktop
        $('.navbar-default #search').click(function () {
          $('#searchHolder').stop().slideToggle();
        });

        // Search toggle - mobile
        $('.mobiBanner #searchMobile').click(function () {
          $('#searchHolderMobile').stop().slideToggle();
        });

        // Match heights function
        // function matchHeight(group) {
        //   tallest = 0;
        //   group.each(function() {
        //       thisHeight = $(this).height();
        //       if(thisHeight > tallest) {
        //         tallest = thisHeight;
        //       }
        //   });
        //   group.height(tallest);
        // }

        function matchHeights() {
          var windowWidth = $(window).width();

          // Changed to use jquery-match-height plugin

          // matchHeight($('#socialFeed .socialCarousel .socialMatch'));
          // matchHeight($('#socialFeed .socialCarousel .item .inr'));
          $('#socialFeedHome .socialCarousel .socialMatch').matchHeight({ byRow: true });
          $('#socialFeedHome .socialCarousel .item .inr').matchHeight({ byRow: true });
          $('#socialFeedHome').css('opacity', '1');

          // $('#socialContainer .content .socialMatch').matchHeight();
          // $('#socialContainer .content .item .inr').matchHeight();
          // $('#socialContainer').css('opacity', '1');

          if (windowWidth > 767) {
            // matchHeight($('.navbar-default .container .topRow .inr'));
            // matchHeight($('.introBoxes .homeBoxHeightMatch'));
            $('.navbar-default .container .topRow .inr').matchHeight({ byRow: true });
            $('.introBoxes .homeBoxHeightMatch').matchHeight({ byRow: true });
            $('.blog').matchHeight({ byRow: true });

            $('.navbar-default .container .topRow .inr').css('opacity', '1');
            $('.introBoxes .homeBoxHeightMatch').css('opacity', '1');
          } else {
            $('#socialFeed').css('opacity', '1');
            $('.navbar-default .container .topRow .inr').css('opacity', '1');
            $('.introBoxes .homeBoxHeightMatch').css('opacity', '1');
          }

        }

        function filmListingResize() {
          $('.' + 'element').each(function (index) {
            $(this).find('.featured-image').height('auto');
            objectHeight = $(this).height();
            $(this).find('.featured-image').height(objectHeight + 1);
          });
        }

        function onReadyResponsive(initial, windowWidth) {
          matchHeights();
          filmListingResize();
        }

        function onLoadResponsive(initial, windowWidth) {
          matchHeights();
          filmListingResize();
        }

        $(window).load(function () {
          onLoadResponsive(true, getWindowWidth());
        });

        $(window).resize(function () {
          var windowWidth = getWindowWidth();
          onLoadResponsive(false, windowWidth);
          onReadyResponsive(false, windowWidth);
        });




        //Hook intro blog next/preview links for button controls.
        function setBlogButtonControls() {
          const nextButton = document.querySelectorAll('#nextBtn');
          const prevButton = document.querySelectorAll('#prevBtn');
          const prevInnerBtn = document.querySelectorAll('#prevBtn button');

          //Check if prev link is active
          if(document.querySelector('.prev')) {
            const prevControl = document.querySelector('.prev');

            prevButton.forEach(function(item) {
              item.setAttribute('href', prevControl.getAttribute('href'));
            });

            //Enable previous button if prev link is present
            prevInnerBtn.forEach(function(item) {
              item.classList.toggle('btn-disabled');
            });
          }

          if(document.querySelector('.next')) {
            const nextControl = document.querySelector('.next');

            nextButton.forEach(function(item) {
              item.setAttribute('href', nextControl.getAttribute('href'));
            })
          }
        }
        setBlogButtonControls();

        window.YT.ready(function() {
          // banner videos
          if ($('.banner-home').length) {
            const $videoWraps = $('.banner-home').find('.video-wrap');
            if ($videoWraps.length) {
              $videoWraps.each(function() {
                $(this).attr('id', 'vid-' + Math.ceil(Math.random()*10000));
                const videoId = $(this).data('vid-id');
                const elId = $(this).attr('id');

                const player = new window.YT.Player(elId, {
                  width: '1920',
                  height: '1080',
                  videoId: videoId,
                  playerVars: {
                    'playsinline': 1,
                    'muted': 1,
                    // 'autoplay': 1,
                    'controls': 0,
                    'rel': 0,
                    'loop': 1,
                    'modestbranding': 1,
                    'origin': window.location.origin,
                  },
                  events: {
                    'onReady': (event) => {
                      event.target.mute();
                      event.target.playVideo();
                    },
                    'onStateChange': (event) => {
                      if (event.data == 0) {
                        setTimeout(() => {
                          event.target.mute();
                          event.target.playVideo();

                          //Trigger homebanner slider on video end.
                          const bannerCarousel = $('.banner-home .owl-carousel').trigger('next.owl.carousel');

                          //Get active slide.
                          const activeSlide = $('.banner-home .owl-carousel .owl-item.active .carousel-cell');

                          //Manually added one here as the js seems to be running before the dom updates the active slide.
                          let slideIndex = parseInt(activeSlide[0].dataset.index);
                          let newSlideIndex = slideIndex + 1;
                          setSliderControlsIndex(newSlideIndex);

                        }, 50);
                      }
                    },
                  },
                });
              });
            }
          }

          //Added function to handle slider controls.
          function setSliderControlsIndex(currentSlide) {

            const homeCarouselControls = $('.banner-home .banner-controls .banner-slide-btn');

            // Remove any exisiting active state.
            $(homeCarouselControls).each(function(index) {
              $( this ).removeClass('active');
            })

            //Set active state for active control.
            $(homeCarouselControls[currentSlide]).addClass('active');
          }


          // 2 col videos
          const $twoColVideos = $('.two-column-videos');
          if ($twoColVideos.length) {
            $twoColVideos.each(function() {
              $(this).find('.video-item').each(function() {
                const $this = $(this);

                const $video = $this.find('.item-video');

                $video.attr('id', 'vid-' + Math.ceil(Math.random()*10000));
                const videoId = $video.data('vid-id');
                const elId = $video.attr('id');

                const player = new window.YT.Player(elId, {
                  width: '480',
                  height: '640',
                  videoId: videoId,
                  playerVars: {
                    'playsinline': 1,
                    'controls': 1,
                    'modestbranding': 1,
                    'origin': window.location.origin,
                  },
                  events: {
                    'onStateChange': (event) => {
                      if (event.data == 0) {
                        setTimeout(() => {
                          $this.find('.item-splash-wrap').show();
                        }, 50);
                      }
                    },
                  },
                });

                $this.find('.item-splash-btn').on('click', function(e) {
                  e.preventDefault();
                  e.stopPropagation();

                  $this.find('.item-splash-wrap').hide();
                  player.playVideo();
                });

              });
            });
          }
        });

      },



      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page

        // Home banner
        if ($('.banner-home').length) {
          const bannerCarousel = $('.banner-home .owl-carousel').owlCarousel({
            loop: ($(".banner-home .owl-carousel .item").length > 1) ? true: false,
            items: 1,
            mouseDrag: false,
            touchDrag: false,
            pullDrag: false,
          });

          $('.banner-home .banner-controls .banner-slide-btn').on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();

            const slideIndex = $(this).data('index');

            $('.banner-home .owl-carousel').data('owlCarousel').to(slideIndex);

            $('.banner-home .banner-controls .banner-slide-btn').removeClass('active');
            $(this).addClass('active');
          });
        }

        // Home social carousel
        if ($('.socialCarousel').length) {
          $('.socialCarousel').owlCarousel({
            // center: true,
            loop: true,
            autoWidth: true,
            dots: true,
            responsiveClass: true,
            responsive: {
              0: {
                items: 1,
                autoWidth: false,
              },
              480: {
                items: 2,
                autoWidth: true,
              },
              768: {
                items: 3,
              },
              992: {
                items: 4,
              },
              1200: {
                items: 8,
                // autoWidth:false,
              },
            }


          });
        }

      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'social': {
      init: function () {
        // JavaScript to be fired on the social page

      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS

        // Instantiate MixItUp
        $('#mixItUp').mixItUp({
          load: { filter: 'all' }
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
